import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import Image from '#/components/shared/ui/Image';
import ActionButtons from '#/components/shared/advisor-helpers/action-buttons';
import useLocales from '#/hooks/useLocales';

type Props = {
  advisorImage: string;
  isComplexForm: boolean;
  changeAnswer?: () => void;
  handleContinueProcess?: () => void;
  isFinancialAdvisor?: boolean;
};

export default function NonPartnerView({
  advisorImage,
  isComplexForm = false,
  changeAnswer,
  handleContinueProcess,
  isFinancialAdvisor,
}: Props) {
  const { translate } = useLocales();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginY: 2,
        }}
      >
        <Image src={advisorImage} alt="Placeholder" />
      </Box>
      <ActionButtons isComplexForm={isComplexForm} changeAnswer={changeAnswer}>
        <Button variant="contained" onClick={handleContinueProcess}>
          <Typography>
            {String(
              translate(
                `global.complexFormModal.${
                  isFinancialAdvisor ? 'financialSearchLabel' : 'searchLabel'
                }`
              )
            )}
          </Typography>
        </Button>
      </ActionButtons>
    </>
  );
}
